import React from "react";
import dayjs from 'dayjs';
import { useAuth } from "../context/auth";
import '../main.css'
import MenuBar from '../clients/commons/Menu'
import PrimaryFoot from '../clients/commons/PrimaryFooter'
import ReportPage from '../clients/accountantPage'
import Headers from '../clients/headers'


function Admin(props) {
  const { setAuthTokens } = useAuth();
  
  function logOut() {
    setAuthTokens();
  }
    
    
  return ( 
    <div className="generic">
		<div className="logins" align="center">	 			
			<Headers />
			<MenuBar /><br/><br/>
			<h1>Accounting Reports (<small>{dayjs().format("YYYY-MM-DD")}</small>)</h1><br/><br/>			
			<h2>Please remit your withholding Tax, EI and CPP to CRA directly</h2><br/><br/>
			<ReportPage />			
			<br/><br/><PrimaryFoot />
		</div>
	</div>
  );
}

export default Admin;