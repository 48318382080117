import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'

class setupBanking extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:26,program:'', ProgName:'', ProjName:'', Resp:'', Nodata:'',clientPrice:[],selectedLName:'',
			LName: '', BName: '', defaultpay:'',payfreqs:'',starting:'',payperiod:[],delaydays:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);	
		this.handleCIDChange=this.handleCIDChange.bind(this);
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/clientprice.php')
			.then(result => {												
				this.setState({clientPrice:result.data})								
		})		
	}	
		
	handleCIDChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({BName:this.state.clientPrice.filter(sub=>sub[0]==event.target.value).map(sub=>sub[2]).[0]})
		this.setState({ProgName:this.state.clientPrice.filter(sub=>sub[0]==event.target.value).map(sub=>sub[3]).[0]})
		this.setState({delaydays:this.state.clientPrice.filter(sub=>sub[0]==event.target.value).map(sub=>sub[4]).[0]})
		this.setState({Resp:""});
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})			
		this.setState({Resp:""});
	}
	
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {program,ProgName, BName,delaydays} = this.state					
		if (this.state.program == "") {
				alert('Please select client')
				return false
		}
		if(!BName) {
			alert('Please enter per payment employee rate')
			return false
		}
		if(!ProgName) {
			alert('Please enter at least charging rate for each payment')
			return false
		}											
		const userObject={
			user:this.state.user,
			names:this.state.names,
			adminitem:this.state.adminitem,
			program:this.state.program,				
			ProgName:this.state.ProgName,				
			BName: this.state.BName,
			delaydays:this.state.delaydays
		}
		axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
			.then((res) => {						
				this.setState({Resp:res.data});											
			}).catch((error) => {
				console.log(error)
			});				
	}
	
	render() {				
		return (		
			<div>      		
				<CommonHeader titles="Client Price"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Select Client</h2></label>							
						<select name="program" id="program" style={{width:'150px'}} value={this.state.program} onChange={this.handleCIDChange}>
							<option value="">--Select Client--</option>
							{this.state.clientPrice.map((id,index) => (<option value={id[0]} key={index}>{id[1]}</option>))}										
						</select><br/><br/>							
					</div>
					{(()=>{if(this.state.program !== "") {return(<div>
						<label><h2>Per Employee Rate Per Payment</h2></label>
						<input id="BName" name="BName" value = {this.state.BName} style={{width:'160px'}} onChange={this.handleChange} />
						<br/><br/>								
						<label><h2>At Least Charging Fee Per Payment</h2></label>
						<input id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} onChange={this.handleChange} />
						<br/><br/>		
						<label><h2>Delay Payment Days</h2></label>
						<input id="delaydays" name="delaydays" value = {this.state.delaydays} style={{width:'160px'}} onChange={this.handleChange} />
						<br/><br/>	
						<h1>{this.state.Resp}</h1><br/><br/>						
					</div>)}})()}						
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupBanking;