import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
import CommonHeader from '../clients/commons/Head'

class setupBanking extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			adminitem:25,program:'', ProgName:'', ProjName:'', Resp:'', Nodata:'',freqs:[],selectedLName:'',
			LName: '', BName: '', defaultpay:'',payfreqs:'',starting:'',payperiod:[],
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);		
		this.handleCampusChange=this.handleCampusChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/frequency.php', para)
			.then(result => {												
				this.setState({freqs:result.data})								
		})		
		axios.post(process.env.REACT_APP_API_ENV+'api/deletepaymentperiod.php')
			.then(result => {				
				this.setState({Nodata:result.data})
				this.setState({payperiod:result.data})										
		})	
		
	}			
	
	handleCampusChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({BName:''})
		if(event.target.value !=="") {
			axios.post(process.env.REACT_APP_API_ENV+'api/setupsystemprice.php')
				.then(result => {		
					this.setState({BName:result.data[0]})
					this.setState({ProgName:result.data[1]})
					this.setState({LName:result.data[2]})
					this.setState({ProjName:result.data[3]})
					this.setState({payfreqs:result.data[4]})					
				})			
		} 
		this.setState({Resp:""});
	}	
	
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
	
	

	StartingPoint =(Freq) =>{			
		const LB=[]	
		if(Freq==1||Freq==2) {			
			for(let i=0;i<10;i++) {
				LB[i]=<option value={dayjs().day(0).add(i+1,'week').format("YYYY-MM-DD")}>{dayjs().day(0).add(i+1,'week').format("YYYY-MM-DD")}</option>;
			}
		} else if(Freq==3||Freq==4) {
			for(let i=0;i<10;i++) {
				LB[i]=<option value={dayjs().date(1).add(i+1,'month').format("YYYY-MM-DD")}>{dayjs().date(1).add(i+1,'month').format("YYYY-MM-DD")}</option>
			}
		} 			
		return LB
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {program,ProgName, ProjName, LName, BName, payfreqs} = this.state					
		if (this.state.program == "") {
				alert('Please select manage rate type, Add or Update?')
				return false
		}
		if(this.state.program == "1" || this.state.program=="2")  {			
			if(!BName) {
				alert('Please enter rate for each payment each employee')
				return false
			}
			if(!ProgName) {
				alert('Please enter least charging rate for each payment')
				return false
			}					
			if(!LName) {
				alert('Please enter coupon amount, if no coupon, please enter 1')
				return false
			}									
			if(!ProjName) {
				alert('Please enter coupon starting date, if no coupon, please leave the date without any change')
				return false
			}					
			if(!payfreqs) {
				alert('Please enter coupon ending date, if no coupon, please leave the date without any change')
				return false
			}					
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,
				LName: this.state.LName,
				ProgName:this.state.ProgName,
				ProjName:this.state.ProjName,
				BName: this.state.BName,
				payfreqs:this.state.payfreqs				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} 
	}
	
	render() {				
		return (		
			<div>      	
				<CommonHeader titles="System Price"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage System Price</h2></label>							
						<select name="program" id="program" style={{width:'150px'}} value={this.state.program} onChange={this.handleCampusChange}>
							<option value="">--Manage Payment Periods--</option>
							<option value="1">Set Up New Price</option>						
							<option value="2">Update System Price</option>																														
						</select><br/><br/>							
					</div>
					{(()=>{if(this.state.program !== "") {return(<div>
						<label><h2>Per Employee Per Payment Rate</h2></label>
						<input id="BName" name="BName" value = {this.state.BName} style={{width:'160px'}} onChange={this.handleChange} />
						<br/><br/>								
						<label><h2>At Least Charging Fee Per Payment</h2></label>
						<input id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} onChange={this.handleChange} />
						<br/><br/>								
						<label><h2>Coupon</h2></label>
						<input id="LName" name="LName" value = {this.state.LName} style={{width:'160px'}} onChange={this.handleChange} />
						<br/><p>If no coupon, set to 1</p><br/>	
						<label><h2>Coupon Starting Date <small>(YYYY-MM-DD)</small></h2></label>
						<input id="ProjName" name="ProjName" value = {this.state.ProjName} style={{width:'160px'}} onChange={this.handleChange} maxLength="10" />
						<br/><br/>								
						<label><h2>Coupon Ending Date<small>(YYYY-MM-DD)</small></h2></label>
						<input id="payfreqs" name="payfreqs" value = {this.state.payfreqs} style={{width:'160px'}} onChange={this.handleChange}  maxLength="10"/>
						<br/><br/>															
						<h1>{this.state.Resp}</h1><br/><br/>						
					</div>)}})()}						
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupBanking;