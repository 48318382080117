import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
import CommonHeader from '../clients/commons/Head'

class setupBanking extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			adminitem:24,program:'', COMP:[], ProgName:'', ProjName:'', Resp:'', Nodata:'',freqs:[],selectedLName:'',
			LName: '', BName: '', defaultpay:'',payfreqs:'',starting:'',payperiod:[], 
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);		
		this.handleCampusChange=this.handleCampusChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {	
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/frequency.php', para)
			.then(result => {												
				this.setState({freqs:result.data})								
		})		
		axios.post(process.env.REACT_APP_API_ENV+'api/deletepaymentperiod.php')
			.then(result => {				
				this.setState({Nodata:result.data})
				this.setState({payperiod:result.data})										
		})			
	}	
		
	handleBankChange = event => {		
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({payfreqs:''})
		if(event.target.value!=="") {
			axios.post(process.env.REACT_APP_API_ENV+'api/lastpaymentdate.php?CID='+ event.target.value)
				.then(result => {									
					this.setState({ProgName:result.data[1]})												
					this.setState({LName:result.data[2]})					
					this.setState({ProjName:result.data[3]})	
				})
		}		
		this.setState({Resp:""});
	}
	
	handleCampusChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({BName:''})
		if(event.target.value !=="") {
			axios.post(process.env.REACT_APP_API_ENV+'api/findregisteredcompany.php')
				.then(result => {	
					if(result.data[0][0] != "No") {
						this.setState({Nodata:result.data})
						this.setState({COMP:result.data})					
					} 
				})			
		} 
		this.setState({Resp:""});
	}	
	
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
	
	

	StartingPoint =(Freq) =>{			
		const LB=[]	
		if(Freq==1||Freq==2) {			
			for(let i=0;i<10;i++) {
				LB[i]=<option value={dayjs().day(0).add(i+1,'week').format("YYYY-MM-DD")}>{dayjs().day(0).add(i+1,'week').format("YYYY-MM-DD")}</option>;
			}
		} else if(Freq==3||Freq==4) {
			for(let i=0;i<10;i++) {
				LB[i]=<option value={dayjs().date(1).add(i+1,'month').format("YYYY-MM-DD")}>{dayjs().date(1).add(i+1,'month').format("YYYY-MM-DD")}</option>
			}
		} 			
		return LB
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {program,ProgName, ProjName, LName, BName, defaultpay,payfreqs,starting} = this.state					
		if (this.state.program == "") {
				alert('Please select manage payment period type, Add, Update or Delete?')
				return false
		}
		if(this.state.program == "1")  {			
			if(!BName) {
				alert('Please select company')
				return false
			}
			if(!defaultpay) {
				alert('Please select how many years')
				return false
			}					
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,
				LName: this.state.selectedLName,
				ProgName:this.state.ProgName,
				ProjName:this.state.ProjName,
				BName: this.state.BName,
				defaultpay:this.state.defaultpay				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.program == "2")  {			
			if(!BName) {
				alert('Please select company')
				return false
			}
			if(!payfreqs) {
				alert('please select payment frequency')
				return false
			}		
			if(!starting) {
				alert('Please select starting date for the updating')
				return false
			}
			if(!defaultpay) {
				alert('Please select how many years')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,
				LName: this.state.selectedLName,
				ProgName:this.state.ProgName,
				ProjName:this.state.ProjName,
				BName: this.state.BName,
				defaultpay:this.state.defaultpay,
				payfreqs:this.state.payfreqs,
				starting:this.state.starting
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.program == "3")  {			
			if(!BName) {
				alert('Please select company')
				return false
			}				
			if(!starting) {
				alert('Please select starting date for the updating')
				return false
			}			
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,
				LName: this.state.selectedLName,
				ProgName:this.state.ProgName,
				ProjName:this.state.ProjName,
				BName: this.state.BName,
				starting:this.state.starting
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} 
	}
	
	render() {				
		return (		
			<div>      
				<CommonHeader titles="Payroll Dates"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Payment Periods</h2></label>							
						<select name="program" id="program" style={{width:'150px'}} value={this.state.program} onChange={this.handleCampusChange}>
							<option value="">--Manage Payment Periods--</option>
							<option value="1">Set Up New Payment Periods</option>						
							<option value="2">Update Payment Periods</option>												
							<option value="3">Delete Payment Periods</option>												
						</select><br/><br/>							
					</div>
					{(()=>{if(this.state.program !== "") {return(<div>
							<label><h2>Choose Company</h2></label>
							<select id="BName" name="BName" value = {this.state.BName} style={{width:'160px'}} onChange={this.handleBankChange}>
								<option value="">--Select Company--</option>									
								{this.state.COMP.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
							</select><br/><br/>	
							{(()=>{if(this.state.BName !== "") {return(<div>
								<label><h2>Current Last Payment Beginning Date</h2></label>
								<input id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} readOnly />
								<br/><br/>								
								<label><h2>Current Last Payment Ending Date</h2></label>
								<input id="LName" name="LName" value = {this.state.LName} style={{width:'160px'}} readOnly />
								<br/><br/>	
								<label><h2>Current Last Payment Period</h2></label>
								<input id="ProjName" name="ProjName" value = {this.state.ProjName} style={{width:'160px'}} readOnly />
								<br/><br/>	
								{(()=>{if(this.state.program == "2") {return(<div>
									<label><h2>Payment Frequncy</h2></label>
									<select id="payfreqs" name="payfreqs" value = {this.state.payfreqs} style={{width:'160px'}} onChange={this.handleChange}>
										<option value="">--Select Frequncy --</option>																			
										{this.state.freqs.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}																			
									</select><br/><br/>										
								</div>)}})()}
								{(()=>{if(this.state.program == "2" && this.state.payfreqs !== "") {return(<div>
										<label><h2>Starting From</h2></label>										
										<select id="starting" name="starting" value = {this.state.starting} style={{width:'160px'}} onChange={this.handleChange}>
											<option value="">--select date--</option>
											{this.StartingPoint(this.state.payfreqs)}
										</select><br/><br/>	
								</div>)}})()}
								{(()=>{if(this.state.program == "3") {return(<div>
										<label><h2>Delete Periods Starting From</h2></label>										
										<select id="starting" name="starting" value = {this.state.starting} style={{width:'160px'}} onChange={this.handleChange}>
											<option value="">--select date--</option>											
											{this.state.payperiod.filter(sub=>sub[0]==this.state.BName).map((sub,index) => (<option value={sub[3]} key={index}>{sub[1]+' ~ '+sub[2]}</option>))}			
										</select><br/><br/>	
								</div>)}})()}
								{(()=>{if(this.state.program == "1"||(this.state.program == "2" && this.state.payfreqs != "")) {return(<div>
									<label><h2>Extend Years</h2></label>
									<select id="defaultpay" name="defaultpay" value = {this.state.defaultpay} style={{width:'160px'}} onChange={this.handleChange}>
										<option value="">--Select years --</option>									
										<option value="1">--One Year --</option>									
										<option value="2">--Two Years --</option>									
										<option value="3">--Three Years --</option>									
										<option value="4">--Four Years --</option>									
										<option value="5">--Five Years --</option>									
										<option value="6">--Over Five Years --</option>																	
									</select><br/><br/>									
								</div>)}})()}
							</div>)}})()}
							<h1>{this.state.Resp}</h1><br/><br/>						
						</div>)}})()}						
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupBanking;